import React, { Suspense } from 'react'
import { Layout } from 'components/Layout'
import Seo from 'components/Layout/Seo'
import styled from 'styled-components'
import useWindowSize from '../hooks/useGatsbyWindowSize'
import OnlyOnDesktop from '../components/Slices/EditorNotAvailableOnMobileSlice'

const Container = styled.div`
  display: flex;
  height: calc(100vh - 72px);
`

const ClientSideOnlyLazy = React.lazy(() =>
  import("../components/Slices/LazyLoadedEmailEditor")
)

const EmailEditorDemo = () => {
  const isSSR = typeof window === "undefined"
  const { width } = useWindowSize()
  const pageName = 'Semplates - The AWS SES Template Editor'
  const breadcrumbList = [
    { position: 1, name: pageName, path: 'editor' },
  ]

  return (
    <Layout noSignupSlice>
      <Seo
        title={pageName}
        pathname="editor"
        breadcrumbList={breadcrumbList}
        organizationLogo
      />
      <Container>
        {(!isSSR && width > 1024) ? (
          <Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </Suspense>
        ) : <OnlyOnDesktop />}
      </Container>
    </Layout>
  )
}

export default EmailEditorDemo
