import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding: 0 32px;
  display: grid;
  height: 100%;
  place-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  width: 100vw;
`

const Disclaimer = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  text-align: center;
`

const OnlyOnDesktop = () => (
  <Container>
    <Disclaimer>Please note that the Editor is only accessible on desktop devices. <br />Thank you for your understanding.</Disclaimer>
  </Container>
)

export default OnlyOnDesktop